import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import { useFt } from 'hooks'
import config from 'config'
import { Switch, Route, Redirect } from 'router'
import links, { routeAnalytics } from 'links'
import { updateAnalyticsContext } from 'analytics'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useDevice } from 'device'
import { useUser } from 'modules/user'

// too small for extraction
import HasAuth from 'containers/HasAuth/HasAuth'
import HasNeverSubscribed from 'containers/HasNeverSubscribed/HasNeverSubscribed'
import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'


import redirects from './redirects'
import DynamicPage from 'pages/DynamicPage/DynamicPage'


const fallback = <PageSkeleton />

// Layouts are in loadable for better code splitting
const MainLayout = loadable(() => import('layouts/MainLayout/MainLayout'), { fallback })
const LandingLayout = loadable(() => import('layouts/LandingLayout/LandingLayout'), { fallback })
const MinimalLayout = loadable(() => import('layouts/MinimalLayout/MinimalLayout'), { fallback })
const MinimalFAQLayout = loadable(() => import('layouts/MinimalFAQLayout/MinimalFAQLayout'), { fallback })

const AuthPageProvider = loadable(() => import('containers/AuthPageProvider/AuthPageProvider'), { fallback })
const SubscriptionLayout = loadable(() => import('containers/SubscriptionLayout/SubscriptionLayout'), { fallback })
const ResolvePostponed = loadable(() => import('containers/ResolvePostponed/ResolvePostponed'), { fallback })

// Pages
const LandingPage = loadable(() => import('pages/landing/LandingPage/LandingPage'), { fallback })
const ReferralLandingPage = loadable(() => import('pages/landing/ReferralPage/ReferralPage'), { fallback })

const LoginPage = loadable(() => import('pages/auth/LoginPage/LoginPage'), { fallback })
const RegisterPage = loadable(() => import('pages/auth/RegisterPage/RegisterPage'), { fallback })
const MagicLinkContinuePage = loadable(() => import('pages/auth/MagicLinkContinuePage/MagicLinkContinuePage'), { fallback })
const ForgotPasswordPage = loadable(() => import('pages/auth/ForgotPasswordPage/ForgotPasswordPage'), { fallback })
const ResetPasswordPage = loadable(() => import('pages/auth/ResetPasswordPage/ResetPasswordPage'), { fallback })
const RecoverPasswordPage = loadable(() => import('pages/auth/RecoverPasswordPage/RecoverPasswordPage'), { fallback })
const FacebookEmailPage = loadable(() => import('pages/auth/FacebookEmailPage/FacebookEmailPage'), { fallback })

const QueuePage = loadable(() => import('pages/QueuePage/QueuePage'), { fallback })
const SubscriptionStatusPage = loadable(() => import('pages/profile/SubscriptionStatusPage/SubscriptionStatusPage'), { fallback })
const OrderHistoryPage = loadable(() => import('pages/profile/OrderHistoryPage/OrderHistoryPage'), { fallback })
const PersonalInformationPage = loadable(() => import('pages/profile/PersonalInformationPage/PersonalInformationPage'), { fallback })
const BillingPage = loadable(() => import('pages/profile/BillingPage/BillingPage'), { fallback })
const ShippingPage = loadable(() => import('pages/profile/ShippingPage/ShippingPage'), { fallback })
const ReviewsPage = loadable(() => import('pages/profile/ReviewsPage/ReviewsPage'), { fallback })
const RemoveAccountConfirmationPage = loadable(() => import('pages/profile/RemoveAccountConfirmationPage/RemoveAccountConfirmationPage'), { fallback })

const TrackingPage = loadable(() => import('pages/TrackingPage/TrackingPage'), { fallback })

const MainPage = loadable(() => import('pages/MainPage/MainPage'), { fallback })

const BrandsPage = loadable(() => import('pages/BrandsPage/BrandsPage'), { fallback })
const BrandPage = loadable(() => import('pages/brand/BrandPage/BrandPage'), { fallback })
const COAREnhancedPage = loadable(() => import('pages/brand/COAREnhancedPage/COAREnhancedPage'), { fallback })
const SeoPage = loadable(() => import('pages/SeoPage/SeoPage'), { fallback })

const FeaturedBrandPage = loadable(() => import('pages/FeaturedBrandPage/FeaturedBrandPage'), { fallback })

const TheEditPage = loadable(() => import('pages/TheEditPage/TheEditPage'), { fallback })
const TheShopPage = loadable(() => import('pages/TheShopPage/TheShopPage'), { fallback })

const CCPAPage = loadable(() => import('pages/legal/CCPAPage/CCPAPage'), { fallback })
const TermsPage = loadable(() => import('pages/legal/TermsPage/TermsPage'), { fallback })
const PrivacyPage = loadable(() => import('pages/legal/PrivacyPage/PrivacyPage'), { fallback })
const AccessibilityPage = loadable(() => import('pages/legal/AccessibilityPage/AccessibilityPage'), { fallback })

const AboutPage = loadable(() => import('pages/info/AboutPage/AboutPage'), { fallback })
const PressPage = loadable(() => import('pages/info/PressPage/PressPage'), { fallback })

const CollectionsPage = loadable(() => import('pages/collections/CollectionsPage/CollectionsPage'), { fallback })
const CollectionPage = loadable(() => import('pages/collections/CollectionPage/CollectionPage'), { fallback })

const FOMListPage = loadable(() => import('pages/fom/FOMListPage/FOMListPage'), { fallback })
const FOMPage = loadable(() => import('pages/fom/FOMPage/FOMPage'), { fallback })
const ProductPage = loadable(() => import('pages/product/ProductPage/ProductPage'), { fallback })
const GiftSetPage = loadable(() => import('pages/product/GiftSetPage/GiftSetPage'), { fallback })

const GiftsPage = loadable(() => import('pages/GiftsPage/GiftsPage'), { fallback })
const GiftHubPage = loadable(() => import('pages/GiftHubPage/GiftHubPage'), { fallback })
const GiftCardPage = loadable(() => import('pages/GiftCardPage/GiftCardPage'), { fallback })
const GiftSubscriptionPage = loadable(() => import('pages/GiftSubscriptionPage/GiftSubscriptionPage'), { fallback })
const GiftCardClaimPage = loadable(() => import('pages/GiftCardClaimPage/GiftCardClaimPage'), { fallback })
const GiftSetsPage = loadable(() => import('pages/catalog/GiftSetsPage/GiftSetsPage'), { fallback })

const ReferralPage = loadable(() => import('pages/NewReferralPage/NewReferralPage'), { fallback })

const FragranceCatalogPage = loadable(() => import('pages/catalog/FragranceCatalogPage/FragranceCatalogPage'), { fallback })
const BeautyCatalogPage = loadable(() => import('pages/catalog/BeautyCatalogPage/BeautyCatalogPage'), { fallback })
const NewArrivalsPage = loadable(() => import('pages/catalog/NewArrivalsPage/NewArrivalsPage'), { fallback })
const BestsellersPage = loadable(() => import('pages/catalog/BestsellersPage/BestsellersPage'), { fallback })
const CleanFragrancesPage = loadable(() => import('pages/catalog/CleanFragrancesPage/CleanFragrancesPage'), { fallback })
const CandlesPage = loadable(() => import('pages/catalog/CandlesPage/CandlesPage'), { fallback })
const CasesPage = loadable(() => import('pages/catalog/CasesPage/CasesPage'), { fallback })
const AccessoriesPage = loadable(() => import('pages/catalog/AccessoriesPage/AccessoriesPage'), { fallback })
const BathAndBodyPage = loadable(() => import('pages/catalog/BathAndBodyPage/BathAndBodyPage'), { fallback })
const FullSizeFragrancesPage = loadable(() => import('pages/catalog/FullSizeFragrancesPage/FullSizeFragrancesPage'), { fallback })
const MiniBottlesPage = loadable(() => import('pages/catalog/MiniBottlesPage/MiniBottlesPage'), { fallback })
const ShopSamplesPage = loadable(() => import('pages/catalog/ShopSamplesPage/ShopSamplesPage'), { fallback })
const TravelSizePage = loadable(() => import('pages/catalog/TavelSizePage/TravelSizePage'), { fallback })
const HolidayPage = loadable(() => import('pages/catalog/HolidayPage/HolidayPage'), { fallback })
const GiftsUnder50Page = loadable(() => import('pages/catalog/GiftsUnder50/GiftsUnder50'), { fallback })

const CardUpdatePage = loadable(() => import('pages/CardUpdatePage/CardUpdatePage'), { fallback, ssr: false })

const ResubscribePage = loadable(() => import('pages/ResubscribePage/ResubscribePage'), { fallback, ssr: false })

const UpgradeProductPage = loadable(() => import('pages/UpgradeProductPage/UpgradeProductPage'), { fallback })
const DowngradePage = loadable(() => import('pages/DowngradePage/DowngradePage'), { fallback })

const ScentProfilePage = loadable(() => import('pages/ScentProfilePage/ScentProfilePage'), { fallback, ssr: false })
const SmartRecommendationsPage = loadable(() => import('pages/SmartRecommendationsPage/SmartRecommendationsPage'), { fallback })

const RequestPage = loadable(() => import('pages/RequestPage/RequestPage'), { fallback })

// const Page404 = loadable(() => import('pages/Page404/Page404'), { fallback })
const TestPage = loadable(() => import('pages/TestPage/TestPage'), { fallback })

// payment
const PaymentPage = loadable(() => import('pages/payment/PaymentPage/PaymentPage'), { fallback, ssr: false })
const GiftSubscriptionRedeemPage = loadable(() => import('pages/payment/GiftSubscriptionRedeemPage/GiftSubscriptionRedeemPage'), { fallback, ssr: false })

// thank you pages
const ThankYouPage = loadable(() => import('pages/thankYou/ThankYouPage/ThankYouPage'), { fallback, ssr: false })
const GiftSubscriptionRedeemThankYouPage = loadable(() => import('pages/thankYou/GiftSubscriptionRedeemThankYouPage/GiftSubscriptionRedeemThankYouPage'), { fallback, ssr: false })
const GiftCardClaimThankYouPage = loadable(() => import('pages/thankYou/GiftCardClaimThankYouPage/GiftCardClaimThankYouPage'), { fallback, ssr: false })
const CaseSubscriptionThankYouPage = loadable(() => import('pages/thankYou/CaseSubscriptionThankYouPage/CaseSubscriptionThankYouPage'), { fallback, ssr: false })
const CandleSubscriptionThankYouPage = loadable(() => import('pages/thankYou/CandleSubscriptionThankYouPage/CandleSubscriptionThankYouPage'), { fallback, ssr: false })
const DriftSubscriptionThankYouPage = loadable(() => import('pages/thankYou/DriftSubscriptionThankYouPage/DriftSubscriptionThankYouPage'), { fallback, ssr: false })
const SamplesSubscriptionThankYouPage = loadable(() => import('pages/thankYou/SamplesSubscriptionThankYouPage/SamplesSubscriptionThankYouPage'), { fallback, ssr: false })

const DemoUserPage = loadable(() => import('pages/DemoUserPage/DemoUserPage'), { fallback })

const CaseSubscriptionPage = loadable(() => import('pages/CaseSubscriptionPage/CaseSubscriptionPage'), { fallback })
const CandleSubscriptionPage = loadable(() => import('pages/CandleSubscriptionPage/CandleSubscriptionPage'), { fallback })
const DriftSubscriptionPage = loadable(() => import('pages/DriftSubscriptionPage/DriftSubscriptionPage'), { fallback })
const DriftSubscriptionProductPage = loadable(() => import('pages/DriftSubscriptionProductPage/DriftSubscriptionProductPage'), { fallback })
const SamplesSubscriptionPage = loadable(() => import('pages/SamplesSubscriptionPage/SamplesSubscriptionPage'), { fallback })
const FeaturedCandlePage = loadable(() => import('pages/product/FeaturedCandlePage/FeaturedCandlePage'), { fallback })
const TossInPage = loadable(() => import('pages/TossInPage/TossInPage'), { fallback })
const BuyQueuePage = loadable(() => import('pages/BuyQueuePage/BuyQueuePage'), { fallback })
const PrivateSalePage = loadable(() => import('pages/PrivateSalePage/PrivateSalePage'), { fallback })

const NotesLibraryPage = loadable(() => import('pages/NotesLibraryPage/NotesLibraryPage'), { fallback })
const NoteDetailsPage = loadable(() => import('pages/NoteDetailsPage/NoteDetailsPage'), { fallback })

const FragranceFamiliesPage = loadable(() => import('pages/fragranceFamily/FragranceFamiliesPage/FragranceFamiliesPage'), { fallback })
const FragranceFamilyDetailsPage = loadable(() => import('pages/fragranceFamily/FragranceFamilyDetailsPage/FragranceFamilyDetailsPage'), { fallback })

const ScentbirdSelectPage = loadable(() => import('pages/catalog/ScentbirdSelectPage/ScentbirdSelectPage'), { fallback })

const ScentHoroscopePage = loadable(() => import('pages/horoscope/ScentHoroscopePage/ScentHoroscopePage'), { fallback })
const ScentHoroscopeSignPage = loadable(() => import('pages/horoscope/ScentHoroscopeSignPage/ScentHoroscopeSignPage'), { fallback })

const LimitedDropLandingPage = loadable(() => import('pages/LimitedDropPage/LimitedDropPage'), { fallback })
const LimitedDropProductPage = loadable(() => import('pages/LimitedDropProductPage/LimitedDropProductPage'), { fallback })

const CareersPage = loadable(() => import('pages/CareersPage/CareersPage'), { fallback })

const CollaborationPage = loadable(() => import('pages/collaborations/CollaborationPage/CollaborationPage'), { fallback })
const CollaborationQuizPage = loadable(() => import('pages/collaborations/CollaborationQuizPage/CollaborationQuizPage'), { fallback })

const FragranceAwardsPage = loadable(() => import('pages/FragranceAwardsPage/FragranceAwardsPage'), { fallback })

// redirects to different pages, depending on the user gender
const GenderRedirect: React.FC<{ path: string, female: string, male: string }> = ({ path, female, male }) => {
  const { gender } = useUser()
  const to = gender === 'FEMALE' ? female : male
  return (
    <Redirect path={path} to={to} />
  )
}

// Update analytics context on each route change or reset it to default value
const handleRouteChange = (match: { path: string }) => {
  const pageContext = routeAnalytics[match?.path]
  updateAnalyticsContext(pageContext || {})
}

const Routes = () => {
  useEffect(() => {
    setTimeout(() => {
      // preload after rendering to provide better UX
      MainLayout.preload()
      MinimalLayout.preload()
      AuthPageProvider.preload()
      SubscriptionLayout.preload()
      ResolvePostponed.preload()
    }, 5000)
  }, [])

  const { isMobile } = useDevice()

  const isCandleSubscriptionEnabled = useFt(constants.features.candleSubscription)
  const isDriftSubscriptionEnabled = useFt(constants.features.driftSubscription)
  const isSamplesSubscriptionEnabled = useFeatureIsOn(constants.features.addonSamples)
  const isTheEditPageEnabled = useFt(constants.features.theEdit)
  const isGiftHubEnabled = useFt(constants.features.giftHub)
  const isHolidayShopEnabled = useFt(constants.features.holidayCatalogue)
  const isHoliday22CardUpdate60Enabled = useFt(constants.features.holiday22CardUpdate60)
  const isHoliday22Resubscribe60Enabled = useFt(constants.features.holiday22Resubscribe60)
  const isGiftSetProductPageEnabled = useFt(constants.features.giftSetProductPage)
  const isScentHoroscopeEnabled = useFt(constants.features.scentHoroscope)
  const isShopSamplesEnabled = useFt(constants.features.shopSamples)
  const isLimitedDropsEnabled = useFt(constants.features.limitedDrops)
  const isTravelSizeFragrancesEnabled = useFt(constants.features.travelSizeFragrances)
  const isCOARShopPageEnabled = useFt(constants.features.coarShopPage)
  const isGiftsUnder50PageEnabled = useFt(constants.features.giftsUnder50Page)
  const isTwelveDaysPageEnabled = useFeatureIsOn(constants.features.twelveDaysPage)
  const isStrapiCareersPageEnabled = useFeatureIsOn(constants.features.strapiCareersPage)
  const isEllimanPerksPageEnabled = useFeatureIsOn(constants.features.ellimanPerksPage)
  const isAccessPerksEnabled = useFeatureIsOn(constants.features.accessPerksPage)
  const isStrapiCollaborationQuizEnabled = useFeatureIsOn(constants.features.strapiCollaborationQuiz)
  const isMiniBottlesPageEnabled = useFeatureIsOn(constants.features.miniBottlesPage)
  const isDigitalFragranceAwardsEnabled = useFeatureIsOn(constants.features.digitalFragranceAwards)
  const isTheShopRootPageEnabled = useFeatureIsOn(constants.features.theShopRootPage)
  const isAccessibilityPageEnabled = useFeatureIsOn(constants.features.accessibilityPage)

  const isNotProduction = config.runEnv !== 'prod'

  // ft, ab, cookies
  return (
    <Switch onChange={__CLIENT__ ? handleRouteChange : null}>
      <>
        {
          redirects.map(({ from, to }) => (
            <Redirect key={from} path={from} to={to} code={301} />
          ))
        }
      </>
      {/* Landings with own layouts for any kind of users */}
      {
        isNotProduction && (
          <Route path="/test-page" component={TestPage} />
        )
      }
      <Route path={links.scentProfile} component={ScentProfilePage} />
      <Route path={links.smartRecommendations} component={SmartRecommendationsPage} />
      {
        isStrapiCollaborationQuizEnabled && (
          <Route path={links.pr.quiz} component={CollaborationQuizPage} />
        )
      }
      <MinimalLayout>
        <HasAuth isRegisterFlow>
          <ResolvePostponed withNotification={false}>
            <Route path={links.subscribe} component={PaymentPage} />
            <Route path={links.checkout} component={PaymentPage} />
            <Route path={links.queueRedeem} component={PaymentPage} />
            <Route path={links.tossInRedeem} component={PaymentPage} />
            <Route path={links.giftCardPayment} component={PaymentPage} />
            <Route path={links.giftSubscriptionPayment} component={PaymentPage} />
          </ResolvePostponed>
        </HasAuth>
      </MinimalLayout>
      <MinimalLayout>
        <HasAuth isRegisterFlow>
          <ResolvePostponed withNotification={false}>
            <Route path={links.giftSubscriptionRedeem} component={GiftSubscriptionRedeemPage} />
          </ResolvePostponed>
        </HasAuth>
      </MinimalLayout>
      <MinimalLayout withFooter>
        <Route path={links.request} component={RequestPage} />
        <HasAuth>
          <Route path={links.profile.removeAccountConfirmation} component={RemoveAccountConfirmationPage} />
        </HasAuth>
      </MinimalLayout>
      <AuthPageProvider>
        <Route path={links.register} component={RegisterPage} />
        <Route path={links.magicLinkContinue} component={MagicLinkContinuePage} />
        <MinimalLayout logoLink={null}>
          <Route path={links.login} component={LoginPage} />
          <Route path={links.forgotPassword} component={ForgotPasswordPage} />
          <Route path={links.resetPassword} component={ResetPasswordPage} />
          <Route path={links.recoverPassword} component={RecoverPasswordPage} />
          <Route path={links.facebookEmail} component={FacebookEmailPage} />
        </MinimalLayout>
      </AuthPageProvider>
      <LandingLayout>
        <HasNeverSubscribed>
          <Route path={links.home} component={LandingPage} />
          <Route path={links.homeMen} component={LandingPage} />
          <Route path={links.offer} component={LandingPage} />
          <Route path={links.offerMen} component={LandingPage} />
          <Route path={links.freeTrial} component={LandingPage} />
          <Route path={links.freeTrialMen} component={LandingPage} />
          <Route path={links.freeTrialCa} component={LandingPage} />
          <Route path={links.freeTrialMenCa} component={LandingPage} />
          <Route path={links.freePerfume} component={LandingPage} />
          <Route path={links.freePerfumeMen} component={LandingPage} />
          <Route path={links.secondMonthFree} component={LandingPage} />
          <Route path={links.secondMonthFreeMen} component={LandingPage} />
          <Route path={links.nift} component={LandingPage} />
          <Route path={links.bouqs} component={LandingPage} />
          <Route path={links.andreDrummond} component={LandingPage} />
          <Route path={links.referral} component={ReferralLandingPage} />
          {
            isTwelveDaysPageEnabled && (
              <Route path={links.twelveDays} component={LandingPage} />
            )
          }
          {
            isEllimanPerksPageEnabled && (
              <Route path={links.ellimanperks} component={LandingPage} />
            )
          }
          {
            isAccessPerksEnabled && (
              <Route path={links.accessPerks} component={LandingPage} />
            )
          }
        </HasNeverSubscribed>
      </LandingLayout>
      <MinimalFAQLayout withCart withLogin>
        <Route path={links.productPerfumeLanding} component={ProductPage} />
      </MinimalFAQLayout>
      <MainLayout>
        <Route path={links.terms} component={TermsPage} />
        <Route path={links.privacy} component={PrivacyPage} />
        <Route path={links.team} component={AboutPage} />
        <Route path={links.press} component={PressPage} />
        <Route path={links.ccpa} component={CCPAPage} />
        <Route path={links.tracking} component={TrackingPage} />
        {
          isTheEditPageEnabled && (
            <Route path={links.shop.theEdit} component={TheEditPage} />
          )
        }
        {
          isStrapiCareersPageEnabled ? (
            <Route path={links.careers} component={CareersPage} />
          ) : (
            <Redirect path={links.careers} to={constants.externalLinks.ripplingJobs} />
          )
        }
        {
          isAccessibilityPageEnabled && (
            <Route path={links.accessibility} component={AccessibilityPage} />
          )
        }
        <ResolvePostponed withNotification>
          <Route path={links.brands} component={BrandsPage} />
          <Route path={links.brand} component={BrandPage} />
          <Route path={links.seoPerfumes} component={SeoPage} />
          <Route path={links.seoColognes} component={SeoPage} />
          <Route path={links.collectionsWomen} component={CollectionsPage} />
          <Route path={links.collectionsMen} component={CollectionsPage} />
          <Route path={links.collectionWomen} component={CollectionPage} />
          <Route path={links.collectionMen} component={CollectionPage} />
          <Route path={links.subscription.perfumeOfTheMonthList} component={FOMListPage} />
          <Route path={links.subscription.cologneOfTheMonthList} component={FOMListPage} />
          <Route path={links.subscription.perfumeOfTheMonth} component={FOMPage} />
          <Route path={links.subscription.cologneOfTheMonth} component={FOMPage} />
          <Route path={links.subscription.currentPerfumeOfTheMonth} component={FOMPage} />
          <Route path={links.subscription.currentCologneOfTheMonth} component={FOMPage} />
          <Route path={links.giftSubscription} component={GiftSubscriptionPage} />
          {
            isGiftsUnder50PageEnabled && (
              <Route path={links.giftsUnder50} component={GiftsUnder50Page} />
            )
          }
          <Route path={links.giftCard} component={GiftCardPage} />
          {
            isGiftHubEnabled ? (
              <Route path={links.gifts} component={GiftHubPage} />
            ) : (
              <Route path={links.gifts} component={GiftsPage} />
            )
          }
          {
            isGiftSetProductPageEnabled ? (
              <Route path={links.giftSet} component={ProductPage} />
            ) : (
              <Route path={links.giftSet} component={GiftSetPage} />
            )
          }
          <GenderRedirect path={links.giftSets.root} female={links.giftSets.perfumes} male={links.giftSets.colognes} />
          <Route path={links.giftSets.colognes} component={GiftSetsPage} />
          <Route path={links.giftSets.unisex} component={GiftSetsPage} />
          <Route path={links.giftSets.perfumes} component={GiftSetsPage} />
          <Route path={links.productGeneral} component={ProductPage} />
          <Route path={links.productPerfume} component={ProductPage} />
          <Route path={links.productWellness} component={ProductPage} />
          <Route path={links.productSkincare} component={ProductPage} />
          <Route path={links.productPersonalCare} component={ProductPage} />
          <Route path={links.productLegacyBody} component={ProductPage} />
          <Route path={links.productMakeup} component={ProductPage} />
          <Route path={links.productExtra} component={ProductPage} />
          <Route path={links.productCandle} component={ProductPage} />
          <Route path={links.productExclusive} component={ProductPage} />
          <Route path={links.productDiffuser} component={ProductPage} />
          <Route path={links.productAccessory} component={ProductPage} />
          <Route path={links.productHomeFragranceAccessory} component={ProductPage} />
          <Route path={links.productCarFreshenerRefill} component={ProductPage} />
          <Route path={links.productCarFreshenerClip} component={ProductPage} />
          <Route path={links.productRoomSpray} component={ProductPage} />
          <Route path={links.ecommerce.productGeneral} component={ProductPage} />
          <Route path={links.ecommerce.productPerfume} component={ProductPage} />
          <Route path={links.ecommerce.productWellness} component={ProductPage} />
          <Route path={links.ecommerce.productSkincare} component={ProductPage} />
          <Route path={links.ecommerce.productPersonalCare} component={ProductPage} />
          <Route path={links.ecommerce.productLegacyBody} component={ProductPage} />
          <Route path={links.ecommerce.productMakeup} component={ProductPage} />
          <Route path={links.ecommerce.productExtra} component={ProductPage} />
          <Route path={links.ecommerce.productCandle} component={ProductPage} />
          <Route path={links.ecommerce.productDiffuser} component={ProductPage} />
          <Route path={links.ecommerce.productAccessory} component={ProductPage} />
          <Route path={links.ecommerce.productHomeFragranceAccessory} component={ProductPage} />
          <Route path={links.ecommerce.productCarFreshenerRefill} component={ProductPage} />
          <Route path={links.ecommerce.productCarFreshenerClip} component={ProductPage} />
          <Route path={links.ecommerce.productRoomSpray} component={ProductPage} />
          <Route path={links.notes} component={NotesLibraryPage} />
          <Route path={links.note} component={NoteDetailsPage} />
          <Route path={links.fragranceFamilies} component={FragranceFamiliesPage} />
          <Route path={links.fragranceFamily} component={FragranceFamilyDetailsPage} />
          <Route path={links.scentbirdSelect} component={ScentbirdSelectPage} />
          <Redirect path={links.saks} to={links.scentbirdSelect} />
          <Route path={links.pr.landing} component={CollaborationPage} />
          <Route path={links.pr.detailed} component={CollaborationPage} />
          {
            isDigitalFragranceAwardsEnabled && (
              <Route path={links.fragranceAwards} component={FragranceAwardsPage} />
            )
          }
          {
            isScentHoroscopeEnabled && (
              <>
                <Route path={links.scentHoroscope} component={ScentHoroscopePage} />
                <Route path={links.scentHoroscopeSign} component={ScentHoroscopeSignPage} />
              </>
            )
          }
          {
            isLimitedDropsEnabled && (
              <>
                <Route path={links.limitedDrop} component={LimitedDropLandingPage} />
                <Route path={links.limitedDropProduct} component={LimitedDropProductPage} />
              </>
            )
          }
          {
            isCOARShopPageEnabled ? (
              <Route path={links.shop.coar} component={COAREnhancedPage} />
            ) : (
              <Redirect path={links.shop.coar} to="/brand/confessions-of-a-rebel" />
            )
          }
          <SubscriptionLayout>
            <Route path={links.subscription.main} component={MainPage} />
            <Route path={links.subscription.perfumes} component={FragranceCatalogPage} />
            <Route path={links.subscription.colognes} component={FragranceCatalogPage} />

            <Route path={links.subscription.beauty} component={BeautyCatalogPage} />

            <GenderRedirect path={links.newArrivals.root} female={links.newArrivals.perfumes} male={links.newArrivals.colognes} />
            <Route path={links.newArrivals.perfumes} component={NewArrivalsPage} />
            <Route path={links.newArrivals.colognes} component={NewArrivalsPage} />

            <GenderRedirect path={links.bestsellers.root} female={links.bestsellers.perfumes} male={links.bestsellers.colognes} />
            <Route path={links.bestsellers.perfumes} component={BestsellersPage} />
            <Route path={links.bestsellers.colognes} component={BestsellersPage} />

            <GenderRedirect path={links.clean.root} female={links.clean.perfumes} male={links.clean.colognes} />
            <Route path={links.clean.perfumes} component={CleanFragrancesPage} />
            <Route path={links.clean.colognes} component={CleanFragrancesPage} />

            {
              isTheShopRootPageEnabled ? (
                <Route path={links.shop.root} component={TheShopPage} />
              ) : isTheEditPageEnabled ? (
                <Redirect path={links.shop.root} to={links.shop.theEdit} />
              ) : (
                <GenderRedirect path={links.shop.root} female={links.shop.perfumes} male={links.shop.colognes} />
              )
            }
            <Redirect path={links.candles} to={links.shop.candlesHomeScents} />
            <Route path={links.shop.candlesHomeScents} component={CandlesPage} />
            <Route path={links.shop.scentbirdCases} component={CasesPage} />
            <Route path={links.shop.accessories} component={AccessoriesPage} />
            <Route path={links.shop.bathBody} component={BathAndBodyPage} />
            <Route path={links.shop.perfumes} component={FullSizeFragrancesPage} />
            <Route path={links.shop.colognes} component={FullSizeFragrancesPage} />

            {
              isShopSamplesEnabled && (
                <>
                  <GenderRedirect path={links.shop.samples.root} female={links.shop.samples.perfumes} male={links.shop.samples.colognes} />
                  <Route path={links.shop.samples.perfumes} component={ShopSamplesPage} />
                  <Route path={links.shop.samples.colognes} component={ShopSamplesPage} />
                </>
              )
            }
            {
              isTravelSizeFragrancesEnabled && (
                <>
                  <GenderRedirect path={links.shop.travelSize.root} female={links.shop.travelSize.perfumes} male={links.shop.travelSize.colognes} />
                  <Route path={links.shop.travelSize.perfumes} component={TravelSizePage} />
                  <Route path={links.shop.travelSize.colognes} component={TravelSizePage} />
                </>
              )
            }
            {
              isMiniBottlesPageEnabled && (
                <>
                  <GenderRedirect path={links.shop.miniBottles.root} female={links.shop.miniBottles.perfumes} male={links.shop.miniBottles.colognes} />
                  <Route path={links.shop.miniBottles.perfumes} component={MiniBottlesPage} />
                  <Route path={links.shop.miniBottles.colognes} component={MiniBottlesPage} />
                </>
              )
            }
            {
              isHolidayShopEnabled ? (
                <Route path={links.shop.holiday} component={HolidayPage} />
              ) : (
                <Redirect path={links.shop.holiday} to={links.shop.perfumes} />
              )
            }
          </SubscriptionLayout>
        </ResolvePostponed>
        {
          isNotProduction && (
            <Route path="/demo-user" component={DemoUserPage} />
          )
        }
        <HasAuth>
          <Route path={links.resubscribe} component={ResubscribePage} />
          <Route path={links.resubscribeNoOffer} component={ResubscribePage} />
          <Route path={links.resubscribe50} component={ResubscribePage} />
          {
            isHoliday22Resubscribe60Enabled ? (
              <Route path={links.resubscribe60} component={ResubscribePage} />
            ) : (
              <Redirect path={links.resubscribe60} to={links.resubscribe} />
            )
          }
          <Route path={links.resubscribeFree} component={ResubscribePage} />
          <Route path={links.resubscribeFreeProduct} component={ResubscribePage} />
          <Route path={links.resubscribeFreeCase} component={ResubscribePage} />
          <Route path={links.upgradeProduct} component={UpgradeProductPage} />
          <Route path={links.downgrade} component={DowngradePage} />
          <Route path={links.invite} component={ReferralPage} />
          <Route path={links.profile.subscriptionStatus} component={SubscriptionStatusPage} />
          <Route path={links.profile.personalInfo} component={PersonalInformationPage} />
          <Route path={links.profile.orderHistory} component={OrderHistoryPage} />
          <Route path={links.profile.billing} component={BillingPage} />
          <Route path={links.profile.shipping} component={ShippingPage} />
          <Route path={links.profile.reviews} component={ReviewsPage} />
          <Route path={links.giftCardClaim} component={GiftCardClaimPage} />
          <Route path={links.giftSubscriptionRedeemThankYou} component={GiftSubscriptionRedeemThankYouPage} />
          <Route path={links.giftCardClaimThankYou} component={GiftCardClaimThankYouPage} />
          <Route path={links.caseSubscriptionThankYou} component={CaseSubscriptionThankYouPage} />
          <Route path={links.caseSubscription} component={CaseSubscriptionPage} />
          {
            isCandleSubscriptionEnabled && (
              <>
                <Route path={links.candleSubscriptionThankYou} component={CandleSubscriptionThankYouPage} />
                <Route path={links.candleSubscription} component={CandleSubscriptionPage} />
                <Route path={links.featuredCandle} component={FeaturedCandlePage} />
              </>
            )
          }
          {
            isDriftSubscriptionEnabled && (
              <>
                <Route path={links.driftSubscriptionThankYou} component={DriftSubscriptionThankYouPage} />
                <Route path={links.driftSubscription} component={DriftSubscriptionPage} />
                <Route path={links.driftSubscriptionPDP} component={DriftSubscriptionProductPage} />
              </>
            )
          }
          {
            isSamplesSubscriptionEnabled && (
              <>
                <Route path={links.samplesSubscription} component={SamplesSubscriptionPage} />
                <Route path={links.samplesSubscriptionThankYou} component={SamplesSubscriptionThankYouPage} />
              </>
            )
          }
          <Route path={links.tossIn} component={TossInPage} />
          <Route path={links.buyQueue} component={BuyQueuePage} />
          <Route path={links.privateSale} component={PrivateSalePage} />
          <Route path={links.featuredBrand} component={FeaturedBrandPage} />
          <ResolvePostponed withNotification>
            <Route path={links.queue} component={QueuePage} />
          </ResolvePostponed>
        </HasAuth>
      </MainLayout>
      <MainLayout withHeaderBanner={false}>
        <HasAuth>
          <Route path={links.cardUpdate} component={CardUpdatePage} />
          <Route path={links.cardUpdate50} component={CardUpdatePage} />
          <Route path={links.cardUpdate10} component={CardUpdatePage} />
          <Route path={links.cardUpdateFreeCase} component={CardUpdatePage} />
          <Route path={links.cardUpdateFreeProduct} component={CardUpdatePage} />
          {
            isHoliday22CardUpdate60Enabled ? (
              <Route path={links.cardUpdate60} component={CardUpdatePage} />
            ) : (
              <Redirect path={links.cardUpdate60} to={links.cardUpdate} />
            )
          }
        </HasAuth>
      </MainLayout>
      <HasAuth>
        <ResolvePostponed withNotification>
          {/* should be after all *ThankYou pages because uses their paths as :sessionId */}
          <Route path={links.thankYou} component={ThankYouPage} />
        </ResolvePostponed>
      </HasAuth>
      <Route path="*" component={DynamicPage} />
    </Switch>
  )
}


export default Routes
