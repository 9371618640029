import React from 'react'
import loadable from '@loadable/component'
import { ModalsRenderer, registerModals } from 'modals'

import CommonModal from 'compositions/modals/CommonModal/CommonModal'
import CommonDrawerModal from 'compositions/modals/CommonDrawerModal/CommonDrawerModal'
import GlobalLoader from 'compositions/GlobalLoader/GlobalLoader'


const fallback = <GlobalLoader withOverlay />

const registry = {
  'commonModal': CommonModal,
  'commonDrawerModal': CommonDrawerModal,
  'fraudModal': loadable(() => import('compositions/modals/FraudModal/FraudModal'), { fallback }),
  'searchModal': loadable(() => import('compositions/modals/SearchModal/SearchModal'), { fallback }),
  'chatAssistanceModal': loadable(() => import('compositions/modals/ChatAssistanceModal/ChatAssistanceModal'), { fallback }),
  'cartModal': loadable(() => import('compositions/modals/CartModal/CartModal'), { fallback }),
  'faqModal': loadable(() => import('compositions/faq/FaqModal/FaqModal'), { fallback }),
  'firstProductModal': loadable(() => import('compositions/modals/FirstProductModal/FirstProductModal'), { fallback }),
  'addressVerificationModal': loadable(() => import('compositions/modals/AddressVerificationModal/AddressVerificationModal'), { fallback }),
  'shippingAddressModal': loadable(() => import('compositions/modals/ShippingAddressModal/ShippingAddressModal'), { fallback }),
  'newPaymentMethodModal': loadable(() => import('compositions/modals/NewPaymentMethodModal/NewPaymentMethodModal'), { fallback }),
  'candleSubscriptionConfirmationModal': loadable(() => import('compositions/modals/CandleSubscriptionConfirmationModal/CandleSubscriptionConfirmationModal'), { fallback }),
  'caseSubscriptionConfirmationModal': loadable(() => import('compositions/modals/CaseSubscriptionConfirmationModal/CaseSubscriptionConfirmationModal'), { fallback }),
  'cardUpdateModal': loadable(() => import('compositions/modals/CardUpdateModal/CardUpdateModal'), { fallback }),
  'productReviewModal': loadable(() => import('compositions/modals/productReview/ProductReviewModal/ProductReviewModal'), { fallback }),
  'productAfterRateAndReviewUpsellModal': loadable(() => import('compositions/modals/productReview/AfterRateAndReviewUpsellModal/AfterRateAndReviewUpsellModal'), { fallback }),
  'productReviewTagsModal': loadable(() => import('compositions/modals/productReview/ProductReviewTagsModal/ProductReviewTagsModal'), { fallback }),
  'afterProductReviewModal': loadable(() => import('compositions/modals/productReview/AfterProductReviewModal/AfterProductReviewModal'), { fallback }),
  'lastShipmentReviewModal': loadable(() => import('compositions/modals/productReview/LastShipmentReviewModal/LastShipmentReviewModal'), { fallback }),
  'verifyShippingAddressModal': loadable(() => import('compositions/modals/VerifyShippingAddressModal/VerifyShippingAddressModal'), { fallback }),
  'resubscribeModal': loadable(() => import('compositions/modals/ResubscribeModal/ResubscribeModal'), { fallback }),
  'resubscribePromoModal': loadable(() => import('compositions/modals/ResubscribePromoModal/ResubscribePromoModal'), { fallback }),
  'addToQueueUpgradeModal': loadable(() => import('compositions/modals/AddToQueueUpgradeModal/AddToQueueUpgradeModal'), { fallback }),
  'putFirstToQueueModal': loadable(() => import('compositions/modals/PutFirstToQueueModal/PutFirstToQueueModal'), { fallback }),
  'addPremiumModal': loadable(() => import('compositions/modals/AddPremiumModal/AddPremiumModal'), { fallback }),
  'outOfStockModal': loadable(() => import('compositions/modals/OutOfStockModal/OutOfStockModal'), { fallback }),
  'needPaymentDataModal': loadable(() => import('compositions/modals/NeedPaymentDataModal/NeedPaymentDataModal'), { fallback }),
  'activateAccountModal': loadable(() => import('compositions/modals/ActivateAccountModal/ActivateAccountModal'), { fallback }),
  'brandOptInModal': loadable(() => import('compositions/modals/BrandOptInModal/BrandOptInModal'), { fallback }),
  'sameProductModal': loadable(() => import('compositions/modals/SameProductModal/SameProductModal'), { fallback }),
  'sameProductAbModal': loadable(() => import('compositions/modals/SameProductModal/SameProductAbModal'), { fallback }),
  'cancelSkipModal': loadable(() => import('compositions/modals/CancelSkipModal/CancelSkipModal'), { fallback }),
  'upgradeSubscriptionModal': loadable(() => import('compositions/modals/UpgradeSubscriptionModal/UpgradeSubscriptionModal'), { fallback }),
  'upgradeSubscriptionSummaryModal': loadable(() => import('compositions/modals/UpgradeSubscriptionSummaryModal/UpgradeSubscriptionSummaryModal'), { fallback }),
  'firstTimeVisitModal': loadable(() => import('compositions/modals/FirstTimeVisitModal/FirstTimeVisitModal'), { fallback }),
  'candleSubscriptionPromoModal': loadable(() => import('compositions/modals/CandleSubscriptionPromoModal/CandleSubscriptionPromoModal'), { fallback }),
  'privateSaleModal': loadable(() => import('compositions/modals/PrivateSaleModal/PrivateSaleModal'), { fallback }),
  'tossInModal': loadable(() => import('compositions/modals/TossInModal/TossInModal'), { fallback }),
  'tryTwoThreeFragrancesModal': loadable(() => import('compositions/modals/TryTwoThreeFragrancesModal/TryTwoThreeFragrancesModal'), { fallback }),
  'mobileAppModal': loadable(() => import('compositions/modals/MobileAppModal/MobileAppModal'), { fallback }),
  'featuredBrandModal': loadable(() => import('compositions/modals/StrapiWSTOModalWithProducts/StrapiWSTOModalWithProducts'), { fallback }),
  'scentbirdSelectModal': loadable(() => import('compositions/modals/ScentbirdSelectModal/ScentbirdSelectModal'), { fallback }),
  'caseSubscriptionPromoModal': loadable(() => import('compositions/modals/CaseSubscriptionPromoModal/CaseSubscriptionPromoModal'), { fallback }),
  'cotyModal': loadable(() => import('compositions/modals/CotyModal/CotyModal'), { fallback }),
  'inviteModal': loadable(() => import('compositions/modals/InviteModal/InviteModal'), { fallback }),
  'continueMobileAppModal': loadable(() => import('compositions/modals/ContinueMobileAppModal/ContinueMobileAppModal'), { fallback }),
  'candleSubscriptionCancelSuccessModal': loadable(() => import('compositions/modals/CandleSubscriptionCancelSuccessModal/CandleSubscriptionCancelSuccessModal'), { fallback }),
  'caseSubscriptionCancelSuccessModal': loadable(() => import('compositions/modals/CaseSubscriptionCancelSuccessModal/CaseSubscriptionCancelSuccessModal'), { fallback }),
  'limitedDropModal': loadable(() => import('compositions/modals/LimitedDropModal/LimitedDropModal'), { fallback }),
  'driftPromoModal': loadable(() => import('compositions/modals/DriftPromoModal/DriftPromoModal'), { fallback }),
  'videoModal': loadable(() => import('compositions/modals/VideoModal/VideoModal'), { fallback }),
  'caseUpsellModal': loadable(() => import('compositions/modals/CaseUpsellModal/CaseUpsellModal'), { fallback }),
  'caseSubscriptionUpsellModal': loadable(() => import('compositions/modals/CaseSubscriptionUpsellModal/CaseSubscriptionUpsellModal'), { fallback }),
  'hereticCaseUpsellModal': loadable(() => import('compositions/modals/caseUpsell/HereticCaseUpsellModal/HereticCaseUpsellModal'), { fallback }),
  'driftRefillClipDisclaimerModal': loadable(() => import('compositions/modals/DriftRefillClipDisclaimerModal/DriftRefillClipDisclaimerModal'), { fallback }),
  'driftSubscriptionConfirmationModal': loadable(() => import('compositions/modals/DriftSubscriptionConfirmationModal/DriftSubscriptionConfirmationModal'), { fallback }),
  'driftSubscriptionPromoModal': loadable(() => import('compositions/modals/DriftSubscriptionPromoModal/DriftSubscriptionPromoModal'), { fallback }),
  'marvelCollaborationModal': loadable(() => import('compositions/modals/collaboration/MarvelCollaborationModal/MarvelCollaborationModal'), { fallback }),
  'strawberryShortcakeCollaborationModal': loadable(
    () => import('compositions/modals/collaboration/StrawberryShortcakeCollaborationModal/StrawberryShortcakeCollaborationModal'),
    { fallback }
  ),
  'strawberryShortcakeCaseUpsellModal': loadable(
    () => import('compositions/modals/caseUpsell/StrawberryShortcakeCaseUpsellModal/StrawberryShortcakeCaseUpsellModal'),
    { fallback }
  ),
  'summerSaleModal': loadable(() => import('compositions/modals/SummerSaleModal/SummerSaleModal'), { fallback }),
  'aiSummaryModal': loadable(() => import('compositions/modals/AiSummaryModal/AiSummaryModal'), { fallback }),
  'vipSubscriberSaleModal': loadable(() => import('compositions/modals/VipSubscriberSaleModal/VipSubscriberSaleModal'), { fallback }),
  'samplesSubscriptionPromoModal': loadable(() => import('compositions/modals/SamplesSubscriptionPromoModal/SamplesSubscriptionPromoModal'), { fallback }),
  'candleSaleModal': loadable(() => import('compositions/modals/CandleSaleModal/CandleSaleModal'), { fallback }),
  'samplesSubscriptionConfirmationModal': loadable(() => import('compositions/modals/SamplesSubscriptionConfirmationModal/SamplesSubscriptionConfirmationModal'), { fallback }),
  'miniBottlesPromoModal': loadable(() => import('compositions/modals/MiniBottlesPromoModal/MiniBottlesPromoModal'), { fallback }),
  'giftSubscriptionPostCutoffModal': loadable(() => import('compositions/modals/GiftSubscriptionPostCutoffModal/GiftSubscriptionPostCutoffModal'), { fallback }),
}

// register all common modals
registerModals(registry)

declare global {
  // ATTN this type is used in modals module
  interface ModalsRegistry extends ExtendModalsRegistry<typeof registry> {}
}

const ModalsRegistry: React.FC = () => {
  return (
    <ModalsRenderer />
  )
}


export default ModalsRegistry
